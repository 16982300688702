.question h4:hover {
    cursor: pointer;
}

.Questions .card {
  border: none;
  padding: 1px 0 1px 5px;
  width: 95%;
  margin: 20px auto;
}

.collapse {
  background-color: rgba(0,0,0,.07);
}

.answer .card-body {
    flex: 1;
}

.card-header {
  padding: 0;
  background-color: rgba(0,0,0,.07);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.accordion>.card:not(:first-of-type) .card-header:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.collapse {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.btn-link {
  width: 100%;
  text-align: left;
}

h6 {
  text-decoration: underline;
}

h5 {
  margin-bottom: 0;
}

ol li {
  list-style: disc;
}

.compare li {
    list-style: none;
}

html {
    overflow: -moz-scrollbars-vertical; 
    overflow-y: scroll;
}

.compare-item::before {
  background-size: 100%;
    display: inline-block;
    width: 20px;
    height: 15px;
    content:"";
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
}

.angular::before {
  background-image: url('https://angular.io/assets/images/logos/angular/angular.png');
}

.react::before {
  background-image: url('https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png');
}

.vue::before {
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/9/95/Vue.js_Logo_2.svg/1200px-Vue.js_Logo_2.svg.png')
}

.oop::before {
  background-image: url('../img/oop.jpg')
}

.functional::before {
  background-image: url('../img/functional.jpg')
}

.procedural::before {
  background-image: url('../img/procedural.jpg')
}

.question-title {
  font-size: 16px;
  margin-bottom: 0;
  margin-left: 5px;
}

.question-category {
  padding-left: 2%;
}