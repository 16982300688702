.in-bl {
    display: inline-block;
    vertical-align: top;
}

.mlr-10 {
    margin: 0px 10px;
}

.js-page-wrapper {
    padding: 4vw;
}

.code-main-wrapper {
    display: inline-block;
    width: 100%;
    transform:translateX(50%);
    margin: 15px auto;
    
}

.code-wrapper {
    padding: 1vw;
    background-color: rgb(228, 228, 228);
    display: inline-block;
    transform:translateX(-50%);
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    border-radius: 10px;
}

code {
    color: rgb(116, 116, 116);
}

.html-wrapper {
    text-align: center;
}

.trigger-btn {
    display: block;
    margin: 15px auto;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    text-align: center;
    background: linear-gradient( to bottom, #6ba6fc, #0564e1, #6ba6fc );
    color: white;
    height: 60px;
    font-size: 22px;
    font-weight: bold;
    font-family: serif;
}

.description-wrapper {
    margin: 15px;
}

div.red {
    background: red;
}