.def-wrapper {
    margin: 2vw 10vw;
}
.def-img {
    width: 80%;
    margin: 0 auto;
    text-align: center;
}
/* .def-img img {
    width: 100%;
    max-width: 700px;
} */

.def-img.xlarge img {
    width: 100%;
}

.def-img.large img {
    width: 80%; 
}

.def-img.medium img {
    width: 60%;
}


.def-img.small img {
    width: 35%;
}

.card {
    padding: 1vw;
    margin: 1vw
}


@media only screen and (max-width: 768px) {
    .def-img {
        width: 100%;
    }
    .def-img.medium img {
        width: 100%;
    }
    .def-img.small img {
        width: 100%;
    }
  }